import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
import { pageTitle } from '../../helpers/PageTitle';

const departmentData = [
  {
    title: 'Clínica Médica',
    iconUrl: '/images/home_2/department_icon_1.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Infectología',
    iconUrl: '/images/home_2/department_icon_2.svg',
   // href: '/departments/department-details',//
  },
  {
    title: 'HIV y Hepatitis Virales',
    iconUrl: '/images/home_2/department_icon_3.svg',
   // href: '/departments/department-details',
  },
  {
    title: 'Salud Sexual',
    iconUrl: '/images/home_2/department_icon_4.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Coronavirus',
    iconUrl: '/images/home_2/department_icon_5.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Diabetología',
    iconUrl: '/images/home_2/department_icon_6.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Urología',
    iconUrl: '/images/home_2/department_icon_7.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Ecografías',
    iconUrl: '/images/home_2/department_icon_8.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Cirugía Plástica',
    iconUrl: '/images/home_2/department_icon_9.svg',
   // href: '/departments/department-details',//
  },
  {
    title: 'Traumatología',
    iconUrl: '/images/home_2/department_icon_10.svg',
   // href: '/departments/department-details',//
  },
  {
    title: 'Nutrición',
    iconUrl: '/images/home_2/department_icon_11.svg',
    //href: '/departments/department-details',//
  },
  {
    title: 'Psicología',
    iconUrl: '/images/home_2/department_icon_12.svg',
   // href: '/departments/department-details',//
  },
];

export default function Departments() {
  pageTitle('Departments');
  return (
    <>

      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title="Centro Médico Formed"
        subTitle="En nuestro centro encontrarás especialidades médicas y turnos prioritarios para afiliados, con atención rápida y eficaz. También contamos con Sinapsia, especializado en neurodesarrollo y salud mental."
      
      />
        <Section bottomMd={125} bottomLg={125} bottomXl={85}>    </Section>
      <Section bottomMd={125} bottomLg={125} bottomXl={85}>
        <DepartmentSectionStyle4
          sectionTitle="Especialidades"
          sectionTitleUp="Nuestras"
          data={departmentData}
        />
      </Section>
      {/* <Section bottomMd={125} bottomLg={125} bottomXl={85}></Section> */}
    </>
  );
}
