import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_1.svg" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">NICOLAS</h3>
                <p className="cs_heading_color mb-0">C.A.B.A</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_2.svg" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">VICTORIA</h3>
                <p className="cs_heading_color mb-0">Lanús, Buenos Aires</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_3.svg" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">MORA</h3>
                <p className="cs_heading_color mb-0">Avellaneda, Buenos Aires</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            La verdad, súper contento con Formed. 
            Tienen un montón de opciones de clínicas y profesionales, 
            así que siempre encontrás donde atenderte cerca. 
            Los planes están bien pensados y hay opciones para todos
            los bolsillos, lo cual es clave hoy en día.
            Siempre me atendieron de diez, así que los recomiendo 100%.

            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            Recomiendo: la atención es excelente, súper personalizada. 
            Siempre hay alguien del otro lado para responderte o solucionar 
            cualquier situación que necesites, con paciencia y dedicación. 
            Los profesionales son de excelente calidad y muy atentos.

            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            La verdad que la super recomiendo están a disposición 
            del Afiliado en cualquier momento y tiene prestaciones 
            de excelencia,responden de inmediato con las credenciales 
            y las autorizaciones.💜🩵
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
