import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import TestimonialSection from '../Section/TestimonialSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import BlogSection from '../Section/BlogSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';


import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Valores',
    subTitle:
      'Ofrecemos cuidado personalizado y servicio de calidad, guiados por una profunda responsabilidad y un fuerte sentido de compromiso hacia cada paciente. Con años de experiencia y una total transparencia, trabajamos para que te sientas respaldado en cada etapa de tu bienestar',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Visión',
    subTitle:
      'Trabajamos día a día para consolidarnos como una empresa referente en atención médica integral, brindando un servicio de excelencia que respalde el bienestar y la confianza de cada uno de nuestros socios.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Misión',
    subTitle: `Nuestra misión es brindar servicios de atención medica integral y personalizada, con un gran respeto y sentido humano por el otro.
Ocuparnos con responsabilidad por la salud de nuestros asociados a través de la escucha continua y acompañamiento profesional, es nuestra prioridad. 
`,
  },
  
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_9.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_10.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_11.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_13.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_14.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_15.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_16.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_17.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_18.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_19.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_20.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_21.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_22.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: '¿Cómo puedo ser socio de FORMED?',
    content:
      'Podés contactarte con nuestros asesores comerciales, quienes te brindarán toda la información necesaria para unirte a la familia FORMED.<br><br> <a href="https://wa.me/5491112345678"  target="_blank"><b>→ COMUNICATE CON UN ASESOR</b></a>',
      
  },
  {
    title: '¿Debo pagar para asociarme?',
    content:
      'Podés asociarte derivando únicamente tu aporte del recibo de sueldo o contratando el plan que mejor se adapte a tus necesidades.',
  },
  {
    title: '¿Cómo obtengo información sobre los diferentes planes de salud?',
    content:
      'En FORMED ofrecemos los siguientes planes de salud:<br><li>FS100<br><li>FS300<br><li>FS500<br><li>FS1000</li> <br>Contactá a nuestros asesores comerciales para que te guíen y te ayuden a elegir el plan que mejor se ajuste a tus necesidades. <br><br> <a href="https://wa.me/5491112345678"  target="_blank"><b>→ COMUNICATE CON UN ASESOR</b></a>',
  },
  {
    title: '¿Cómo puedo sacar un turno con profesionales de la cartilla médica?',
    content:
    'Podés gestionar tus turnos a través de la app MiFORMED o en la sección Cartilla Médica de nuestra web. Esto te permitirá: <li>Realizar búsquedas de profesionales, farmacias y centros de salud en todo el país.</li><li> Descargar la cartilla en tu dispositivo para acceder sin conexión a internet.</li> <br> Para coordinar una cita, comunicate por teléfono con el prestador. Al momento de la consulta, se te pedirá tu DNI y el código de seguridad (token) de la credencial digital.<br>Si no podés asistir, por favor cancelá tu turno para que otro paciente pueda utilizarlo.'
  },
  {
    title: '¿Cómo gestiono mi credencial digital?',
    content:
      'Descargá la app en tu celular para tener tu credencial siempre a mano.<br> Si sos titular o cónyuge, también podés acceder a las credenciales de tu grupo familiar y compartirlas con otras personas que puedan acompañar a tus hijos o a adultos mayores al médico. <br> Se recomienda generar las credenciales de todo tu grupo familiar en el primer ingreso.',
  },
  {
    title: '¿Cómo autorizo prestaciones médicas?',
    content:
      'Podés autorizar tus prestaciones de manera rápida y sencilla a través de la app MiFORMED o en nuestra web.',
  },
  {
    title: '¿Qué debo hacer en caso de una emergencia médica?',
    content:
      'Es importante distinguir entre los tipos de urgencias para garantizar una respuesta inmediata: <br> <b>Emergencia médica:</b> conlleva riesgo de vida. <br> Contacto: <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a> <br><br><b>Urgencia médica:</b> no implica riesgo de vida. <br> Contacto: <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a> <br> <br><b>MESA OPERATIVA:</b> Este servicio brinda asistencia telefónica para derivaciones o inconvenientes en guardias médicas: <a href="https://wa.me/5491127863418" target="_blank">WhatsApp: 112786-3418</a> <br><br><b>SERVICIO MEDICO A DOMICILIO:</b><br> <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a>' ,
  },
];
// FALTA YESI
const blogData = [
  {
    title: 'Descargá nuestra App Mi Formed',
    subTitle:'Ahora podés gestionar autorizaciones, consultar la cartilla y llevar tu credencial siempre con vos.<br><br> <a href="https://apps.apple.com/ar/app/mi-formed/id6474208153">IOS</a></b> <br> <a href="https://play.google.com/store/apps/details?id=ar.com.formed.MI_FORMED&hl=es_AR">Android</a></b>',
    thumbUrl: 'images/home_1/post_1.jpg',
    date: '19 Noviembre 2024',
    href: '/about',
    socialShare: false,
  },
  {
    title: 'Centro Médico Formed',
    subTitle: '¡Ahora contamos con Centro Médico propio en Sarandí! Descubrí todas las especialidades disponibles.',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: '10 Noviembre 2024',
    btnText: 'Conocé más',
    href: '/about',
    socialShare: false,
  },
  {
    title: 'Mesa Operativa 24hs',
    subTitle: 'Nuestra mesa operativa está disponible las 24 horas: de lunes a viernes de 18:00 a 9:00, y los sábados, domingos y feriados al 11-2786-3418.',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: '8 Noviembre 2024',
    btnText: 'Comunicate ahora',
    description: 'esto es una descripcion',
    href: 'tel:+541127863418',
    socialShare: false,
  },
];
const awardData = [
  {
    title: 'Amplia Red de Prestaciones',
    subTitle:
      'Contamos con una red de profesionales, hospitales y clínicas de prestigio para brindar atención médica de calidad.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Planes de Salud Personalizados',
    subTitle:
      'Diseñamos planes de salud adaptados a las necesidades y presupuesto de nuestros clientes.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Tecnología Innovadora',
    subTitle:
      'Ofrecemos acceso rápido y sencillo a credenciales y cartillas para una gestión médica eficiente.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Experiencia de Primera Clase',
    subTitle:
      'Buscamos garantizar una experiencia positiva y satisfactoria en cada interacción médica.',
    iconUrl: 'images/icons/award.svg',
  },
];
const departmentData = [
  {
    title: 'Clínica Médica',
    iconUrl: 'images/home_1/department_icon_1.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Infectología',
    iconUrl: 'images/home_1/department_icon_2.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'VIH y Hepatitis Virales',
    iconUrl: 'images/home_1/department_icon_3.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Chequeo de Salud Sexual',
    iconUrl: 'images/home_1/department_icon_4.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Coronavirus',
    iconUrl: 'images/home_1/department_icon_5.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Diabetología',
    iconUrl: 'images/home_1/department_icon_6.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Urología',
    iconUrl: 'images/home_1/department_icon_7.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Ecografías',
    iconUrl: 'images/home_1/department_icon_8.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Cirugía Plástica',
    iconUrl: 'images/home_1/department_icon_9.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Traumatología',
    iconUrl: 'images/home_1/department_icon_10.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Nutrición',
    iconUrl: 'images/home_1/department_icon_11.svg',
    // href: '/departments/department-details',
  },
  {
    title: 'Psicología',
    iconUrl: 'images/home_1/department_icon_12.svg',
    // href: '/departments/department-details',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title ="Tu Salud <br> Nos Importa"

        subTitle="En Formed, trabajamos para ofrecerte atención médica de confianza, adaptada a cada necesidad y a cada familia"
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="Conocé Formed"
        videoUrl="https://www.youtube.com/embed/AVNUd-ABjCI?si=fkdKh9dkbwZ5KxjN"
        infoList={[
          {
            title: 'ASOCIATE A FORMED',
            subTitle: '11-2669-1391',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'INFO Y TURNOS',
            subTitle: '11-7080-8075',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'POLICONSULTORIO',
            subTitle: 'Av. Mitre 3601 - Sarandí',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Escribinos"
        btnUrl="https://wa.me/541126691391"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Nuestros Valores" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Sobre Nosotros"
          subTitle="Conocé Formed"
          featureList={[
            {
              featureListTitle:
                'Amplia Red de Prestaciones',
              featureListSubTitle:
                'FORMED es una red prestacional joven con un centro médico de primer nivel en Avellaneda, enfocada en ofrecer atención rápida y de calidad para apoyar al sistema de salud. Facilita gestiones a través de su app, atención personalizada por WhatsApp, teléfono, y correo, o de forma presencial en sus sedes. Su objetivo es liderar en atención médica integral y de excelencia para sus socios.',
                        },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle ="Especialidades"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Nuestros Beneficios para tu Salud" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
    
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="MI FORMED"
          subTitle="Descargá nuestra App y llevá tu credencial siempre con vos"
          imgUrlAndroid = 'images/home_1/google-play.png'
          imgUrlIOS = 'images/home_1/app-store.png'
          linkAndroid = 'https://play.google.com/store/apps/details?id=ar.com.formed.MI_FORMED&hl=es_AR'
          linkIOS = 'https://apps.apple.com/ar/app/mi-formed/id6474208153'
        />
      {/* FALTA YESI */}

     
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Novedades"
          sectionTitleUp="FORMED"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
     {/* Start Testimonial */}
     <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Reseñas"
          sectionTitleDown="Nuestros Afiliados"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="FAQS"
          sectionTitleUp="Preguntas Frecuentes"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={50}
        bottomLg={50}
        bottomXl={50}
      >
      
      <BrandsSectionStyle2 data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
