import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import PricingSection from '../Section/PricingSection';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
// import BannerSectionStyle10 from '../Section/BannerSection/BannerSectionStyle10';
const pricingData = [
  {
    title: 'PLAN FS300',
    subTitle:
      'El plan ideal para la tercera edad. Cuidá tu salud sin preocuparte por los costos adicionales. FS 300 incluye internación en habitaciones individuales y cobertura en medicamentos y óptica.',
    //price: '$249',
    //period: '/month',
    featureList: [
      'Los mejores prestadores cerca tuyo',
      'No se abonan copagos *con excepciones',
      'Internación en habitaciones individuales en clínicas seleccionadas',
      'Cobertura en medicamentos: 40% - 70% o 100%',
      'Óptica: cobertura de cristales y armazones',
    ],
    btnText: 'Asociate Ahora',
    btnUrl: '/contact',
    popular: false,
    planColor: '#00BED6',

  },
  // FALTA YESI
  {
    title: 'PLAN FS500',
    subTitle:
      'Un plan flexible que solo paga lo que usas, con cobertura completa en internación y copagos en consultas médicas. Perfecto para quienes buscan calidad a un costo accesible.',
    //price: '$99',
    //period: '/month',
    featureList: [
      'Solo abonas cuando lo usás.',
      'Cobertura en medicamentos: 40%, 70% o 100%',
      'Óptica: cobertura del 40% en cristales, armazones y lentes de contacto.',
      'Consultas médicas y estudios sin límites, con copagos.',
      'Internación sin límite y sin copagos.',
    ],
    btnText: 'Asociate Ahora',
    btnUrl: '/contact',
    popular: true,

  },
  {
    title: 'PLAN FS1000',
    subTitle:
      'Cobertura integral para la familia, sin copagos en consultas ni internaciones. Incluye medicamentos, óptica, asistencia al viajero y más, para cuidar a todos sin preocupaciones.',
    //price: '$149',
    //period: '/month',
    featureList: [
      'No se abonan copagos.',
      'Consultas médicas y estudios sin límites ni copagos.',
      'Internación sin límites ni copagos.',
      'Cobertura en medicamentos: 40%, 70% o 100%.',
      'Óptica: cobertura de cristales, armazones y lentes de contacto.',
      'Asistencia al viajero en territorio nacional.',
      'Tratamientos estéticos (después de 2 años).',
      
    ],
    btnText: 'Asociate Ahora',
    btnUrl: '/contact',
    popular: false,
    planColor: '#000',

  },
  // {
  //   title: 'Dental Health Plan',
  //   subTitle:
  //     'Smile with confidence. Enjoy regular dental check-ups, emergency coverage, and exclusive discounts on dental procedures.',
  //   price: '$79',
  //   period: '/month',
  //   featureList: [
  //     'Dental Check-ups',
  //     'Procedure Discounts',
  //     'Emergency Coverage',
  //     'Oral Health Advice',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: `Women's Health Plan`,
  //   subTitle: `Comprehensive women's health services. Receive expert gynecological care, family planning support, and prenatal/postnatal.`,
  //   price: '$169',
  //   period: '/month',
  //   featureList: [
  //     `Women's Health Services`,
  //     'Gynecological Care',
  //     'Fall Prevention Programs',
  //     'Family Planning',
  //     'Prenatal & Postnatal Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: 'Sports & Fitness Plan',
  //   subTitle:
  //     'Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.',
  //   price: '$119',
  //   period: '/month',
  //   featureList: [
  //     'Sports Injury Assessments',
  //     'Physiotherapy Sessions',
  //     'Sports Medicine Experts',
  //     'Fitness Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
];
const faqData = [
  {
    title: '¿Cómo puedo ser socio de FORMED?',
    content:
    'Podés contactarte con nuestros asesores comerciales, quienes te brindarán toda la información necesaria para unirte a la familia FORMED.<br><br> <a href="https://wa.me/5491112345678"  target="_blank"><b>→ COMUNICATE CON UN ASESOR</b></a>',
  },
  {
    title: '¿Debo pagar para asociarme?',
    content:
    'Podés asociarte derivando únicamente tu aporte del recibo de sueldo o contratando el plan que mejor se adapte a tus necesidades.',
  },
  
  {
    title: '¿Cómo puedo sacar un turno con profesionales de la cartilla médica?',
    content:
    'Podés gestionar tus turnos a través de la app MiFORMED o en la sección Cartilla Médica de nuestra web. Esto te permitirá: <li>Realizar búsquedas de profesionales, farmacias y centros de salud en todo el país.</li><li> Descargar la cartilla en tu dispositivo para acceder sin conexión a internet.</li> <br> Para coordinar una cita, comunicate por teléfono con el prestador. Al momento de la consulta, se te pedirá tu DNI y el código de seguridad (token) de la credencial digital.<br>Si no podés asistir, por favor cancelá tu turno para que otro paciente pueda utilizarlo.'
  },
  {
    title: '¿Cómo gestiono mi credencial digital?',
    content:
    'Descargá la app en tu celular para tener tu credencial siempre a mano. <br> Si sos titular o cónyuge, también podés acceder a las credenciales de tu grupo familiar y compartirlas con otras personas que puedan acompañar a tus hijos o a adultos mayores al médico. Se recomienda generar las credenciales de todo tu grupo familiar en el primer ingreso.',
  },
  {
    title: '¿Qué debo hacer en caso de una emergencia médica?',
    content:
      'Es importante distinguir entre los tipos de urgencias para garantizar una respuesta inmediata: <br> <b>Emergencia médica:</b> conlleva riesgo de vida. <br> Contacto: <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a> <br><br><b>Urgencia médica:</b> no implica riesgo de vida. <br> Contacto: <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a> <br> <br><b>MESA OPERATIVA:</b> Este servicio brinda asistencia telefónica para derivaciones o inconvenientes en guardias médicas: <a href="https://wa.me/5491127863418" target="_blank">WhatsApp: 112786-3418</a> <br><br><b>SERVICIO MEDICO A DOMICILIO:</b><br> <a href="tel:08109990858">0810 999 0858</a> | <a href="tel:49526009">4952-6009</a> | <a href="tel:08108883226">0810 888 3226</a>' ,
  },
];
export default function PricingPlan() {
  pageTitle('Pricing Plan');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/pricing_plan/banner_img.png"
        title="Un plan pensado<br>para vos"
        subTitle="Encontrá tu plan ideal"
        btnText="Asociate Ahora"
        btnUrl="/contact"
      />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Conocé nuestros Planes <br />Asociate a Formed"
          data={pricingData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Preguntas <br />Frecuentes"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        {/* <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Choose Your Plan and Invest in Your Health Today!"
        /> */}
      </Section>
    </>
  );
}
