import React from 'react';
import Spacing from '../../Spacing';
import DepartmentCarousel from '../../Slider/DepartmentCarousel';
import parse from 'html-react-parser';

export default function DepartmentSection({ sectionTitle, bgUrl, data }) {
  return (
    <div className="container">
      <div className="cs_departments cs_style_1">
        <div
          className="cs_departments_bg cs_radius_25"
          style={{
            backgroundImage: `url(${bgUrl})`,
          }}
        />
        <div className={`cs_section_heading cs_style_1 text-center`}>
          <h1 className="cs_section_title cs_fs_72 m-0">{parse(sectionTitle)}</h1>
        </div>

        <Spacing md="72" lg="50" />
        <div className="cs_department_list">
          <DepartmentCarousel data={data} />
        </div>
      </div>
    </div>
  );
}
