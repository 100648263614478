import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Asociate Ahora"
            subTitle="7720-8812 | 11-2669-1391"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="informes@formed.com.ar"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Donde Estamos"
            subTitle="Av. Mitre 3601, Sarandí"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d104984.15625614682!2d-58.42347475033908!3d-34.686135372637196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x95a3335c75ef3167%3A0xfc7250ce5be5f1e7!2sAv.%20Bartolom%C3%A9%20Mitre%203601%2C%20Sarand%C3%AD%2C%20B1872GGB%20Avellaneda%2C%20Provincia%20de%20Buenos%20Aires!3m2!1d-34.6861637!2d-58.341074199999994!5e0!3m2!1ses-419!2sar!4v1731340060627!5m2!1ses-419!2sar"
          allowFullScreen
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
