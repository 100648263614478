import { CONFIG } from './config';

export const endpoints = {
    base_url: CONFIG.BASE_URL,
    services: {
        portal: {
            base: 'api',
            endpoint: {
                post_contact: 'web-contact',
                post_newsletter : 'web-newsletter'
            },
        },
    },
    getUrl(service, action) {
        const base = this.services[service]?.base || '';
        const endpoint = this.services[service]?.endpoint[action] || '';
        return `${this.base_url}${base ? '/' + base : ''}${endpoint ? '/' + endpoint : ''}`;
    },
};
