import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
// import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import GallerySection from '../Section/GallerySection';
// import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';


const featureListData = [
  {
    title: 'Amplia red de Prestaciones',
    subTitle: 'Contamos con una red de profesionales, hospitales y clínicas de prestigio para brindar atención médica de calidad.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Planes de Salud Personalizados',
    subTitle: 'Diseñamos planes de salud adaptados <br />a las necesidades y presupuesto de nuestros clientes.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Tecnología Innovadora',
    subTitle: 'Ofrecemos acceso rápido y sencillo a credenciales y cartillas para una gestión médica eficiente.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Experiencia del Paciente <br> de Primera Clase',
    subTitle:
'Buscamos garantizar una experiencia positiva y satisfactoria en cada interacción médica.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];
// const awardData = [
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Malcolm Baldrige National Quality Award',
//   },
//   { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Healthgrades National’s Best Hospital',
//   },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Joint Commission Gold Seal of Approval',
//   },
// ];

export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Tu Salud <br> Nos Importa"
        subTitle="Somos una red prestacional joven que cuenta con un centro de servicios médico-asistenciales de primer nivel en la localidad de Avellaneda."
      />
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="¿Por qué elegirnos?"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>

      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Centro Médico"
          sectionTitleUp="Conocé nuestro"
          data={galleryData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}></Section>

      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section> */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section> */}
    </>
  );
}
