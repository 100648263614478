import React, { useState } from "react";
import { endpoints } from "../../config/endpoints";
import { toast } from 'react-toastify';
import { CONFIG } from "../../config/config";

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {

  const [newsletter, setNewsletter] = useState({
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const alertInfo = (message) => toast.info(message);

  const handleChange = (e) => {
    setNewsletter({ ...newsletter, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true); // Desactiva el botón al iniciar el envío

    e.preventDefault();

    // Promesa encapsulada
    const submitPromise = new Promise(async (resolve, reject) => {
      try {
        // Generar el token de reCAPTCHA
        const RECAPTCHA_SITE_KEY = CONFIG.RECAPTCHA_SITE_KEY;

        const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: "submit",
        });

        // Preparar los datos para enviar
        const dataToSend = { ...newsletter, 'g-recaptcha-response': token };

        // Generar la URL
        const url_service = endpoints.getUrl("portal", "post_newsletter");

        // Enviar los datos al backend
        const response = await fetch(url_service, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend),
        });

        const result = await response.json();

        console.log('dasdasdasdasdasdasd', result)

        // Manejar la respuesta
        if (!result.success) {
          // Mostrar errores
          reject(result); // Esto lanzará el estado de "error" en toast.promise
        } else {
          // Reiniciar el formulario
          setNewsletter({
            email: "",
          });
          resolve(result.message); // Esto lanzará el estado de "success" en toast.promise
        }
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
        reject("Ocurrió un error al procesar tu solicitud.");
      } finally {
        setIsSubmitting(false); // Reactiva el botón después de recibir la respuesta
      }

    });

    // Integrar la promesa en toast.promise
    toast.promise(submitPromise, {
      pending: "Procesando solicitud...",
      success: {
        render({ data }) {
          return `¡Éxito! ${data}`;
        },
      },
      error: {
        render({ data }) {
          if (data?.errors) {
            // Mostrar errores personalizados
            Object.entries(data.errors).forEach(([field, messages]) => {
              let message = `${field}\n`;
              messages.forEach((msg) => {
                message += `- ${msg}\n`;
              });
              alertInfo(message);
            });
            return data.message || "Error en los datos enviados.";
          }
          // Mostrar mensaje genérico de error
          if (typeof data === "object" && data?.message) {
            return data.message;
          }
          return data || "Ocurrió un error inesperado.";
        },
      },
    });
  };


  return (
    <>
      {label && <p>Dejanos tu Email</p>}
      <form className="cs_newsletter_form">
        <input
          type="text"
          className="cs_form_field"
          placeholder="ejemplo@email.com"
          name="email"
          value={newsletter.email}
          onChange={handleChange}
        />
        <button className="cs_btn cs_style_1" onClick={handleSubmit} disabled={isSubmitting}>
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
