import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Seguinos</h2>
      <div className="cs_social_links">
        <Link to="https://www.facebook.com/profile.php?id=100094247036384">
          <Icon icon="fa-brands:facebook-f" />
        </Link>
    
        <Link to="https://www.linkedin.com/company/formed-medicina-integral">
          <Icon icon="fa-brands:linkedin-in" />
        </Link>

        <Link to="https://www.instagram.com/formedsalud">
          <Icon icon="fa-brands:instagram" />
        </Link>
      </div>
    </div>
  );
}
