const CONFIG_VALUES = {
    production: {
        ENVIRONMENT: 'production',
        BASE_URL: 'https://portal.formed.com.ar',
        STORAGE: '',
        INTERNAL_SERVICES: 'http://localhost:3001',
        GOOGLE_API_KEY: '',
        RECAPTCHA_SITE_KEY: "6Lc_z4wqAAAAAO_FOdMewF9rDrGlG_21doYx-_3E"
    },
    local: {
        ENVIRONMENT: 'local',
        BASE_URL: 'http://127.0.0.1:8000',
        STORAGE: '',
        INTERNAL_SERVICES: 'http://localhost:3001',
        GOOGLE_API_KEY: '',
        RECAPTCHA_SITE_KEY: "6Lc_z4wqAAAAAO_FOdMewF9rDrGlG_21doYx-_3E"

    }
};

let CONFIG = {
    ENVIRONMENT: '',
    BASE_URL: '',
    STORAGE: '',
    INTERNAL_SERVICES: '',
    GOOGLE_API_KEY: '',
    RECAPTCHA_SITE_KEY: '',
};

CONFIG = CONFIG_VALUES[process.env.NODE_ENV] || CONFIG_VALUES.production;

export { CONFIG };
