import React from 'react';

export default function Banner({ bgUrl, imgUrl, title, subTitle, imgUrlAndroid, imgUrlIOS, linkAndroid, linkIOS }) {
  return (
    <div className="container" id='bannerId'>
      <div
        className="cs_banner cs_style_1 cs_bg_filed"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
        <img src={imgUrl} alt="Banner" className="cs_banner_img" style={{ 'height': '161%' }} />
        <h2 className="cs_banner_title cs_white_color cs_fs_72">{title}</h2>
        <p className="cs_banner_subtitle cs_heading_color cs_fs_20 cs_medium m-0">
          {subTitle}
        </p>
        
        <a href={linkAndroid} target='_blank' rel="noreferrer" >
          <img src={imgUrlAndroid} alt="Android" width={200} height={200} />
        </a>
        <a href={linkIOS} target='_blank' rel="noreferrer" >
          <img src={imgUrlIOS} alt="IOS" width={200} height={200} />
        </a>
      </div>
    </div>
  );
}
