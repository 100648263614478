import React from 'react';
import MenuWidget from '../Widget/MenuWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import TextWidget from '../Widget/TextWidget';
import SocialWidget from '../Widget/SocialWidget';
import NewsletterStyle2 from '../Widget/NewsletterStyle2';
const menuDataOne = [
  { title: 'Home', href: '/' },
  { title: 'Nosotros', href: '/about' },
  { title: 'Especialidades', href: '/departments' },
  { title: 'Planes', href: '/pricing-plan' },
  { title: 'Contacto', href: 'contact' },
  { title: 'Mi formed', href: 'https://portal.formed.com.ar/' },
  // { title: 'Appointment', href: '/appointments' },
];

// const menuDataTwo = [
//   { title: 'Testimonials', href: '/' },
//   { title: 'Blog', href: '/blog' },
//   { title: 'Contact Us', href: '/' },
//   { title: 'FAQs', href: '/' },
// ];
const menuDataThree = [
  { title: 'Política de Privacidad', href: '/privacy-policy' },
  //{ title: 'Terms and Conditions', href: '/blog' },
];

export default function FooterStyle2() {
  return (
    <footer className="cs_footer cs_style_2 cs_accent_bg cs_white_color">
      <NewsletterStyle2
        title="Suscribite al<br />Newsletter"
        subTitle="Enterate de todas las novedades,<br> planes y descuentos."
      />
      <div className="container">
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/footer_logo_white.svg"
                text="Formed Medicina Integral"
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          {/* <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div> */}
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2024 FORMED. Reservados todos los derechos.
          </div>
        </div>
      </div>
    </footer>
  );
}
