import React, { useState } from "react";
import { endpoints } from "../../config/endpoints";
import { toast } from 'react-toastify';
import { CONFIG } from "../../config/config";

export default function ContactForm() {

  const [contacto, setContacto] = useState({
    nombre: "",
    email: "",
    motivo: "",
    mensaje: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const alertInfo = (message) => toast.info(message);

  const handleChange = (e) => {
    setContacto({ ...contacto, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true); // Desactiva el botón al iniciar el envío

    e.preventDefault();

    // Promesa encapsulada
    const submitPromise = new Promise(async (resolve, reject) => {
      try {
        // Generar el token de reCAPTCHA
        const RECAPTCHA_SITE_KEY = CONFIG.RECAPTCHA_SITE_KEY;

        const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: "submit",
        });

        // Preparar los datos para enviar
        const dataToSend = { ...contacto, 'g-recaptcha-response': token };

        // Generar la URL
        const url_service = endpoints.getUrl("portal", "post_contact");

        // Enviar los datos al backend
        const response = await fetch(url_service, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend),
        });

        const result = await response.json();

        // Manejar la respuesta
        if (!result.success) {
          // Mostrar errores
          reject(result); // Esto lanzará el estado de "error" en toast.promise
        } else {
          // Reiniciar el formulario
          setContacto({
            nombre: "",
            email: "",
            motivo: "",
            mensaje: "",
          });
          resolve(result.message); // Esto lanzará el estado de "success" en toast.promise
        }
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
        reject("Ocurrió un error al procesar tu solicitud.");
      } finally {
        setIsSubmitting(false); // Reactiva el botón después de recibir la respuesta
      }

    });

    // Integrar la promesa en toast.promise
    toast.promise(submitPromise, {
      pending: "Procesando solicitud...",
      success: {
        render({ data }) {
          return `¡Éxito! ${data}`;
        },
      },
      error: {
        render({ data }) {
          if (data?.errors) {
            // Mostrar errores personalizados
            Object.entries(data.errors).forEach(([field, messages]) => {
              let message = `${field}\n`;
              messages.forEach((msg) => {
                message += `- ${msg}\n`;
              });
              alertInfo(message);
            });
            return data.message || "Error en los datos enviados.";
          }
          // Mostrar mensaje genérico de error
          if (typeof data === "object" && data?.message) {
            return data.message;
          }
          return data || "Ocurrió un error inesperado.";
        },
      },
    });
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <form>
        <div className="row">
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Nombre</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Escribí tu nombre"
              name="nombre"
              value={contacto.nombre}
              onChange={handleChange}
              required
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              type="email"
              className="cs_form_field"
              placeholder="ejemplo@gmail.com"
              name="email"
              value={contacto.email}
              onChange={handleChange}
              required
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Motivo</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Motivo"
              name="motivo"
              value={contacto.motivo}
              onChange={handleChange}
              required
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Mensaje</label>
            <textarea
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Escribí tu mensaje..."
              name="mensaje"
              value={contacto.mensaje}
              onChange={handleChange}
              required
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-12">
            <div className="cs_height_18" />
            <button type="submit" className="cs_btn cs_style_1" onClick={handleSubmit} disabled={isSubmitting}>
              <span>Enviar</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

